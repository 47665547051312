
import {
  Component, Prop, Vue,
} from 'nuxt-property-decorator'
import BaseIcon from '../BaseIcon.vue'

@Component({
  name: 'BaseAccordionElement',
  components: { BaseIcon },
})

export default class BaseAccordionElement extends Vue {
  @Prop() title ?: string

  private open : boolean = false

  private toggle () : void {
    this.open = !this.open
  }

  private start (el : HTMLElement) : void {
    el.setAttribute('style', `height: ${el.scrollHeight}px`)
  }

  private end (el : HTMLElement) : void {
    el.style.height = ''
  }
}
